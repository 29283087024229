﻿/**
 * @file MultiSelectDropdown.scss
 *
 * Endor styling code for Multi Selec tDropdown. Extends main styling for Multi Selec tDropdown
 *
 * @author Piyal Darshana<piyal@studyportals.com>
 * @author Dakshina Wijesuriya <dakshina@studyportals.com>
 * @copyright © 2018 StudyPortals B.V., all rights reserved.
 * @version 1.0.0
 */

// Importing the main Sass file.
@import '../SASSEndor';

.MultiSelectDropdown {
	min-width: 270px;

	.MultiSelectDropdownTriggerContainer {
		position: relative;

		.MultiSelectDropdownTrigger {
			border-bottom: 0.08333rem solid $Grey;
			padding: 1%;
			cursor: pointer;
		}

		.IconNoteChevronDown {
			@include FontSize('N');
			position: absolute;
			right: 0.5rem;
			text-decoration: none;
			top: 0.2rem;
			line-height: 1.5rem;
			cursor: pointer;
			border: 0;
		}
	}
}

.DropdownContainer {
	max-height: 0;
	overflow: visible;
	position: static;
	z-index: 1;

	.Dropdown {
		padding: 0;
		list-style: none;
		overflow: hidden;
		background-color: $White;
		border: 0.08rem solid $Grey;
		position: absolute;
		width: 270px;
		margin-top: 10px;
		z-index: 20;

		.Option {
			@include FontSize('N');
			display: block;
			margin: 0;
			padding: 0.5rem;
			background: $White;
			border-bottom: 0.08rem solid $Grey;

			span {
				padding-left: 0.5rem;
				color: $Grey;
			}

			&:last-child {
				border: 0;
			}

			&:hover,
			&.Selected {
				background-color: $BrandBlue;
				color: $White;
				cursor: pointer;
			}
		}
	}
}
