/**
 * @file GeneralColor.css
 *
 * (Global) portal coloring.
 *
 * @author Stefan Klokgieters <stefan@studyportals.com>
 * @author Alexandra Marginean <alexia@studyportals.com>
 * @copyright © 2014-2017 StudyPortals B.V., all rights reserved.
 * @version 1.1.3
 *
 */

// Grey palette.
$GreyDD: #313233;
$GreyD: #929699;
$Grey: #c4c9cc;
$GreyL: #dce1e5;
$GreyLL: #e8eced;
$GreyLLL: #edf2f4;
$White: #ffffff;

// Secondary palette.
$Red: #c75146;
$RedL: #efcfcc;
$RedLL: #f9efee;

$Green: #44af69;
$GreenL: #cce9d6;
$GreenLL: #eef7f1;

$Yellow: #fcab10;
$YellowL: #fee7c0;
$YellowLL: #fef7e9;

$Blue: #39a9db;
$BlueL: #c9e7f5;
$BlueLL: #edf7fb;

$Purple: #834555;
$PurpleL: #ddccd0;
$PurpleLL: #f3eeef;

$Pink: #ef7674;
$PinkL: #fad9d9;
$PinkLL: #fdf2f2;
