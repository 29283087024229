/**
 * @file Form.scss
 *
 * Endor styling code for forms. Extends main styling for forms

 * @author Mariasoprana Atteo <mariasoprana@studyportals.com>
 * @copyright © 2016 StudyPortals B.V., all rights reserved.
 * @version 1.0.0
 *
 * Default form styling
 *
 * Requirements:
 * - A form should get the .Form class
 * - Inside the form should be element with the class 'Segment', one for every
 * combination (combination of label and input traditionally)
 */

// Importing the main Sass file.
@import '../SASSEndor';

.Form {
	.Segment {
		input[type='text'],
		input[type='number'],
		input[type='date'],
		input[type='email'],
		input[type='tel'],
		input[type='search'],
		input[type='password'],
		select,
		textarea {
			padding: 0.2rem;
			border-color: $GreyBackground;

			&:focus {
				border-color: $Placeholder;
			}
		}
	}

	.errorText {
		color: $RedError !important;
		font-style: italic !important;
	}

	.Error {
		color: $RedError !important;
		border-color: $RedError !important;
	}
}
