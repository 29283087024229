// Importing the main Sass file.
@import './SASSEndor';

/* Make sure to keep this variable synchronised with $navWidth in Packages/Endor/Modules/Header/CSS/Header.scss */
$gapNav: 4rem;

body {
	// Standard hyperlink styling.

	a {
		color: $BrandBlue;

		&:hover,
		&:visited {
			color: $BrandBlueD;
		}
	}

	/* Header */

	header {
		padding: 0.4rem 0;
		background-color: $BrandBlue;

		.Wrapper {
			padding: 0;

			&:before,
			&:after {
				content: '';
				display: table;
				clear: both;
			}
		}

		* {
			max-height: 100%;
		}

		#Logo {
			float: left;

			a {
				float: left;
			}

			img {
				float: left;
				height: 1.75rem;
			}
		}

		.FAQ {
			float: right;
			color: $White;
		}
	}
}

/* Footer */

$FooterHeight: 3rem;

body {
	// Doraemon and OneColumnContainer style push the footer to the bottom of the page when the content is too small

	#doraemon {
		min-height: 100%;

		.OneColumnContainer,
		.LeftSidebarContainer {
			min-height: calc(100vh - #{$FooterHeight});
		}

		.TwoColumnContainer {
			min-height: calc(100vh - 13rem);
		}

		/* Credits bar */

		footer#Credits {
			@include FontSize('SN');
			border-top: 1px solid $GreyBackground;
			background: $GreyDD;
			height: $FooterHeight;
			padding: 1rem 0;
			box-sizing: border-box;
			color: $Grey;
		}
	}

	/* Footer bar */

	footer {
		background: $GreyDD;
		color: $GreyD;
		padding: 20px 0;

		.Container {
			width: 100%;
			max-width: 1220px;
			padding-left: 20px;
			padding-right: 20px;
			margin: 0 auto;

			&::after {
				content: ' ';
				display: block;
				height: 0;
				clear: both;
			}

			.FooterCol {
				width: 25%;
				float: left;

				&:first-child {
					width: 36%;
					margin-right: 14%;
				}
			}
		}

		h5 {
			@include HeadingSize('H5');
			font-weight: normal;
			margin: 20px 0;
			color: $GreyD; /*override default */
		}

		p {
			@include FontSize('N');
			line-height: 1.7em;
			margin-bottom: 1.5rem;
		}

		ul {
			margin: 0;
			padding: 0;

			li {
				list-style: none;
			}
		}

		a {
			@include FontSize('N');
			color: $GreyBackground;
			text-decoration: none;

			&:hover,
			&:focus {
				text-decoration: underline;
			}

			&:visited {
				color: $GreyBackground; /*override default */
			}
		}

		.Copyright {
			@include FontSize('SN');
			text-align: center;
		}

		.Container > .Copyright {
			margin-top: 40px;

			p {
				@include FontSize('SN');
				border-top: 1px solid $GreyD;
				margin: 0;
				padding-top: 20px;
				text-align: center;
			}
		}
	}
}

@include Breakpoint(Small) {
	body footer .Container .FooterCol,
	body footer .Container .FooterCol:first-child {
		width: 100%;
	}
}

/* General */

#block-welcome {
	text-align: center;
}

body .Main .container.ClientHomePage {
	padding-right: 0;
	padding-left: $gapNav;
}
