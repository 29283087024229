/**
 * @file Tags.scss
 *
 * Endor styling code for tags. Extends main styling for tags
 *
 * @author Piyal Darshana<piyal@studyportals.com>
 * @author Dakshina Wijesuriya <dakshina@studyportals.com>
 * @copyright © 2016 StudyPortals B.V., all rights reserved.
 * @version 1.0.0
 */

// Importing the main Sass file.
@import '../SASSEndor';

.TagsContainer {
	background: $BlueLL;
	padding: 0.5rem 0.5rem 0;

	.Tag {
		float: left;
		position: relative;
		margin: 0 0.5rem 0.5rem 0;
		padding: 0 2rem 0 0.6rem;
		line-height: 2rem;
		background: $White;

		.IconNoteCross {
			@include FontSize('N');
			color: $GreyD;
			position: absolute;
			right: 0.4rem;
			text-decoration: none;
			top: 0.25rem;
		}
	}
}
