/**
 * Brand (primary) color palette.
 *
 * @author Stefan Klokgieters <stefan@studyportals.com>
 * @author Alexandra Marginean <alexia@studyportals.com>
 * @version 1.0.1
 */

// Brand orange colors.
$BrandOrangeL: #f96a4b;
$BrandOrange: #f95c39;
$BrandOrangeD: #cc4c2f;

// Brand blue colors.
$BrandBlueL: #4b93b1;
$BrandBlue: #247ba0;
$BrandBlueD: #1b5a75;
