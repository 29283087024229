/**
 * @file Zebra.scss
 *
 * General zebra colouring.
 *
 * @author Stefan Klokgieters <stefan@studyportals.com>
 * @copyright © 2015 StudyPortals B.V., all rights reserved.
 * @version 1.0.0
 */

@import './Templates/Shared/CSS/_SASSGeneral.scss'; // Importing the main SASS file.

table.Zebra {
	thead tr {
		background-color: $GreyLL;
	}

	tr:nth-child(even) {
		background-color: $GreyLLL;
	}
}

ul,
ol {
	&.Zebra {
		li:nth-child(even) {
			background-color: $GreyLLL;
		}
	}
}
