/**
 * @file ClickableText.scss
 *
 * Generic clickable text (not button, not anchor) styling.
 *
 * @author Bram van der Putten <bram@studyportals.com>
 * @author Stefan Klokgieters <stefan@studyportals.com>
 * @copyright © 2015 StudyPortals B.V., all rights reserved.
 * @version 1.2.0
 */

@import '../SASSGeneral.scss'; // Importing the main Sass file.

button.Textlink,
input[type='button'].Textlink,
input[type='submit'].Textlink,
input[type='reset'].Textlink,
span.Clickable {
	background-color: transparent;
	font-size: inherit;
	font-family: inherit;
	border: 0;
	padding: 0;
	margin: 0;
	color: $BrandBlue;
	cursor: pointer;

	&::-moz-focus-inner {
		padding: 0;
		border: 0;
	}

	&:hover {
		color: $BrandBlueD;
		text-decoration: underline;
	}
}
