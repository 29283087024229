/**
 * @file _SASSGeneral.scss
 *
 * All the basic needed Sass functions, mixins and other main styling includes.
 *
 * @author Stefan Klokgieters <stefan@studyportals.com>
 * @author Stanley Clark <stanley@studyportals.com>
 * @author Johan Voorhout <johan@studyportals.com>
 * @copyright © 2014-2017 StudyPortals B.V., all rights reserved.
 * @version 2.6.0
 */

/*
--------------------------------------------------------------------------------
	(Global) portal colors
--------------------------------------------------------------------------------
*/
// Portal specific colors (located in the project "Branding" folder)

@use 'sass:math';

@import 'PortalColors';

// General color palette
@import 'GeneralColors';

/*
--------------------------------------------------------------------------------
	Font and heading sizes
--------------------------------------------------------------------------------
*/
// General font sizes (body text only)
@import 'FontSizes';

// Heading sizes (headings only)
@import 'HeadingSizes';

/*
--------------------------------------------------------------------------------
	Z-index
--------------------------------------------------------------------------------
*/
// General font sizes
@import 'Z-Index';

/*
--------------------------------------------------------------------------------
	(Element) spacing
--------------------------------------------------------------------------------
*/
@import 'Spacing';

/*
--------------------------------------------------------------------------------
	Breakpoint mixins
--------------------------------------------------------------------------------
*/

@mixin Breakpoint($Arguments...) {
	@each $Parameter in $Arguments {
		// 480px / 16px = 30rem
		@if $Parameter == Small {
			@media #{all} and (max-width: 480px) {
				@content;
			}
		}
		// 481px / 16px = 30.063rem and 768px / 16px = 48rem
		@else if $Parameter == Medium {
			@media #{all} and (min-width: 481px) and (max-width: 768px) {
				@content;
			}
		}
		// 769px / 16px = 48.063rem and 1024px / 16px = 64rem
		@else if $Parameter == Large {
			@media #{all} and (min-width: 769px) and (max-width: 1024px) {
				@content;
			}
		}
		// 1025px / 16px = 64.0625rem
		@else if $Parameter == ExtraLarge {
			@media #{all} and (min-width: 1025px) {
				@content;
			}
		} @else {
			@error 'Please use a valid breakpoint!';
		}
	}
}

/*
--------------------------------------------------------------------------------
	General mixins
--------------------------------------------------------------------------------
*/

// Mixin for a clearfix.
@mixin Clearfix() {
	&:before,
	&:after {
		content: '';
		display: block;
	}

	&:after {
		clear: both;
	}
}

// Mixin for transform.
@mixin Transform($transform) {
	-webkit-transform: $transform;
	transform: $transform;
}

// Mixins for Flexbox.
@mixin DisplayFlexbox() {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

@mixin DisplayInlineFlexbox() {
	display: -webkit-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
}

@mixin Flex($flex) {
	-webkit-flex: $flex;
	-ms-flex: $flex;
	flex: $flex;
}

@mixin FlexDirection($direction) {
	-webkit-flex-direction: $direction;
	-ms-flex-direction: $direction;
	flex-direction: $direction;
}

@mixin FlexFlow($flow) {
	-webkit-flex-flow: $flow;
	-ms-flex-flow: $flow;
	flex-flow: $flow;
}

@mixin FlexWrap($wrap) {
	flex-wrap: $wrap;
	-webkit-flex-wrap: $wrap;
	-ms-flex-wrap: $wrap;
}

@mixin AlignItems($align) {
	-webkit-align-items: $align;
	-ms-flex-align: $align;
	align-items: $align;
}

@mixin AlignContent($align) {
	-webkit-align-content: $align;
	-ms-flex-line-pack: $align;
	align-content: $align;
}

@mixin AlignSelf($align) {
	-webkit-align-self: $align;
	-ms-flex-item-align: $align;
	align-self: $align;
}

@mixin JustifyContent($value) {
	-webkit-justify-content: $value;

	// IE10 value variant for space between.
	@if $value == space-between {
		-ms-flex-pack: justify;
	} @else {
		-ms-flex-pack: $value;
	}

	justify-content: $value;
}

@mixin Order($value) {
	-webkit-box-ordinal-group: $value; /* OLD - iOS 6-, Safari 3.1-6 */
	-ms-flex-order: $value; /* TWEENER - IE 10 */
	-webkit-order: $value; /* NEW - Chrome */
	order: $value; /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

// Mixin for centering elements.
@mixin CenterElement {
	position: absolute;
	top: 50%;
	left: 50%;
	@include Transform(translate(-50%, -50%));
}

// Mixin for making images grayscale.
@mixin ImageGrayscale($grayscale) {
	-webkit-filter: grayscale($grayscale);
	-moz-filter: grayscale($grayscale);
	filter: grayscale($grayscale);
}

// Mixin for text in columns.
@mixin ColumnCount($value) {
	-webkit-column-count: $value;
	-moz-column-count: $value;
	column-count: $value;
}

// Controls the gap between the columns horizontally.
@mixin ColumnGap($value) {
	@if unit($value) != 'rem' {
		@warn 'No "rem" value specified';
	}

	-webkit-column-gap: $value;
	-moz-column-gap: $value;
	column-gap: $value;
}

// Styles the gap between the columns horizontally.
@mixin ColumnRule($value) {
	-webkit-column-rule: $value;
	-moz-column-rule: $value;
	column-rule: $value;
}

// To control the behavior of the columns.
@mixin ColumnBreakInside($value) {
	-webkit-column-break-inside: $value;
	page-break-inside: $value;
	break-inside: $value;
}

// Create a container filling overlay with a certain padding (usually 0)
@mixin overlay($padding) {
	position: absolute;
	top: $padding;
	left: $padding;
	right: $padding;
	bottom: $padding;
}

// Handles the visual feedback when a user selects something.
@mixin UserSelect($value) {
	-webkit-user-select: $value;
	-moz-user-select: $value;
	-ms-user-select: $value;
	user-select: $value;
}

/*
--------------------------------------------------------------------------------
	Linear Gradient Mixin And Functions
--------------------------------------------------------------------------------
*/

/// Convert an angle
///
/// @param {Number} $value - Value to convert
/// @param {String} $unit - Unit to convert to
/// @return {Number} Converted angle

@function gradient-convert-angle($value, $unit) {
	$convertable-units: deg grad turn rad;
	$conversion-factors: 1 math.div(10grad, 9deg) math.div(1turn, 360deg) math.div(3.1415926rad, 180deg);

	@if index($convertable-units, unit($value)) and index($convertable-units, $unit) {
		@return math.div($value, nth($conversion-factors, index($convertable-units, unit($value)))) *
			nth($conversion-factors, index($convertable-units, $unit));
	}

	@warn "Cannot convert `#{unit($value)}` to `#{$unit}`.";
}

/// Test if `$value` is an angle
///
/// @param {*} $value - Value to test
/// @return {Bool}

@function is-gradient-direction($value) {
	$is-direction: index(
		(
			to top,
			to top right,
			to right top,
			to right,
			to bottom right,
			to right bottom,
			to bottom,
			to bottom left,
			to left bottom,
			to left,
			to left top,
			to top left
		),
		$value
	);
	$is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));

	@return $is-direction or $is-angle;
}

/// Convert a direction to legacy syntax
///
/// @param {Keyword | Angle} $value - Value to convert
/// @require {function} is-direction
/// @require {function} convert-angle

@function legacy-gradient-direction($value) {
	@if is-gradient-direction($value) == false {
		@warn "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be an angle or a direction";
	}

	$conversion-map: (
		to top: bottom,
		to top right: bottom left,
		to right top: left bottom,
		to right: left,
		to bottom right: top left,
		to right bottom: left top,
		to bottom: top,
		to bottom left: top right,
		to left bottom: right top,
		to left: right,
		to left top: right bottom,
		to top left: bottom right
	);

	@if map-has-key($conversion-map, $value) {
		@return map-get($conversion-map, $value);
	}

	@return 90deg - gradient-convert-angle($value, 'deg');
}

/// Mixin printing a linear-gradient
/// as well as a plain color fallback
/// and the `-webkit-` prefixed declaration
///
/// @access public
///
/// @author Stanley Clark <stanley@studyportals.com>
///
/// @param {String | List | Angle} $direction - Linear gradient direction
/// @param {Arglist} $color-stops - List of color-stops composing the gradient

@mixin linear-gradient($direction, $color-stops...) {
	@if is-gradient-direction($direction) == false {
		$color-stops: ($direction, $color-stops);
		$direction: 180deg;
	}

	background: nth(nth($color-stops, 1), 1);
	background: -webkit-linear-gradient(legacy-gradient-direction($direction), $color-stops);
	background: linear-gradient($direction, $color-stops);
}
