/**
 * @file Default.scss
 *
 * (Previous name would be 'common', but this name was used too much for different things so it lost its meaning)
 *
 * Styling that should be used across all portals
 *
 * This file will include styling for original HTML elements.
 * Please put styling for custom elements (like the .Button class) into a separate file in the Patterns/ folder.
 * In other words, if it gets a class, put it in Patterns/, if it's for all h1's, put it here
 *
 * @version 1.4.1
 */

// Importing the main Sass file.
@import 'SASSGeneral.scss';

// Generic button styling.
@import 'Patterns/Button.scss';

// Generic checkbox styling.
@import 'Patterns/Checkbox.scss';

// Generic form styling.
@import 'Patterns/Form.scss';

// Generic popup styling.
@import 'Patterns/Popup.scss';

// Generic popup Styling for Admission Chance.
@import 'Patterns/PowerPopup.scss';

// Generic table styling.
@import 'Patterns/ClickableText.scss';

// Generic zebra styling.
@import 'Patterns/Zebra.scss';

// Globally accessible animation.
@import 'Patterns/Animations.scss';

// Open sans font through Google fonts.
@import 'OpenSans.scss';

body {
	font-family: 'Open Sans', Helvetica, Arial, sans-serif;
	-webkit-text-size-adjust: none;
	color: $GreyDD;
	background-color: $White;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0 0 0.25rem;
		color: $GreyDD;
		font-weight: normal;
	}

	h1 {
		@include HeadingSize('H1');
	}

	h2 {
		@include HeadingSize('H2');
	}

	h3 {
		@include HeadingSize('H3');
	}

	h4 {
		@include HeadingSize('H4');
	}

	h5 {
		@include HeadingSize('H5');
	}

	h6 {
		@include HeadingSize('H6');
	}

	// Standard hyperlink styling.

	a {
		color: $BrandBlue;
		text-decoration: none;

		&:hover {
			color: $BrandBlueD;
			text-decoration: underline;
		}

		&:visited {
			color: $BrandBlueD;
		}
	}

	// Ordered and unordered lists.

	ul {
		list-style-type: square;

		li {
			margin-top: 0.5rem;
			margin-bottom: 0.5rem;

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	ol {
		li {
			margin-top: 0.5rem;
			margin-bottom: 0.5rem;

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	p {
		margin: 0 0 0.75rem;
	}

	abbr {
		text-decoration: none;
		cursor: help;
		border-bottom: 1px dotted;

		// Hack to not show a line on 'touch' devices (to avoid confusion since they won't hover to see more)
		@include Breakpoint(Small, Medium) {
			border-bottom: 0;
		}
	}

	// Give all data-clickable="clickable" a cursor pointer to let the user know the area is clickable.

	[data-clickable='clickable'] {
		cursor: pointer;
	}

	// Editor image alignment. Are used only by the generator.

	img[data-align='right'] {
		float: right;
		margin: 1rem 0 1rem 1rem;
	}

	img[data-align='left'] {
		float: left;
		margin: 1rem 1rem 1rem 0;
	}

	img[data-align='middle'] {
		margin: 1rem auto;
		display: block;
	}

	@include Breakpoint(Small) {
		img[data-align='left'],
		img[data-align='right'] {
			margin: 1rem auto;
			display: block;
		}
	}

	/*
		To attach a top border and vertical spacing on each module on Small and Medium
		or to attach vertical spacing on the Large and ExtraLarge breakpoint just add
		the class 'ModuleDivider' on your column variation
	*/
	@include Breakpoint(Small, Medium) {
		.ModuleDivider .colblock,
		.ModuleDivider.colblock {
			padding-top: 0 !important;
			padding-bottom: 0 !important;
			margin-top: 0 !important;
			margin-bottom: 0 !important;

			div.Module:not(.EmptyModule) {
				padding: 1.5rem 0;
				border-top: 1px solid $Grey;

				/*
					The styling below is needed for when elements inside this element selector
					are using float to position themselves. A solution could be using inline-block
					for the positioning, however these are very rare cases, they do happen.
					So for now the .Module elements are always displayed with a width of a
					100% because of the display block property
				*/

				&:after {
					content: '';
					display: block;
					clear: both;
				}
			}
		}
	}

	@include Breakpoint(Large, ExtraLarge) {
		.ModuleDivider .colblock,
		.ModuleDivider.colblock {
			div.Module {
				&:not(.EmptyModule) {
					margin: 0 0 1.5rem;
				}
			}
		}
	}

	.ModuleDivider {
		/*
			Select the last element with class '.ModuleDivider' and get the last element
			with class '.colblock', then get the last module which doesn't has the
			class 'EmptyModule' and remove the styling.
		*/

		&:last-of-type .colblock:last-of-type div.Module:last-of-type:not(.EmptyModule) {
			padding-bottom: 0;
		}

		/*
			Select the first element with class '.ModuleDivider' and get the first element
			with class '.colblock', then get the first module which doesn't has the
			class 'EmptyModule' and remove the styling.
		*/

		&:first-of-type .colblock:first-of-type > div.Module:first-of-type:not(.EmptyModule) {
			padding-top: 0;
			border-top: none;
		}

		/*
			Select the last module which is wrapped inside the element '.colblock.ModuleDivider' and remove the styling.
		*/

		&.colblock div.Module:last-of-type:not(.EmptyModule) {
			padding-bottom: 0;
			border-bottom: none;
		}
	}

	// -------------------------------- //
	// -------- Helper classes -------- //
	// -------------------------------- //

	// Class to make the body not scrollable, needs JS to work properly.
	// An example of the JS can be found on the OffCanvasFilterMenu.
	// Todo: turn the js part into a pattern that can be used anywhere.

	&.is-notScrollable {
		overflow: hidden;
		position: fixed;
		width: 100%;
		overflow-y: scroll;
	}

	&.is-notScrollableMobile {
		@include Breakpoint(Small, Medium) {
			overflow: hidden;
			position: fixed;
			width: 100%;
			overflow-y: scroll;
		}
	}

	// Hidden implementation.

	.Hidden {
		display: none !important;
	}

	// Show block elements only on Desktop (large+extralarge).

	.DesktopOnlyBlock {
		display: none;

		@include Breakpoint(Large, ExtraLarge) {
			display: block;
		}
	}

	// Show block elements only on Mobile (all - large+extralarge).

	.MobileOnlyBlock {
		display: block !important;

		@include Breakpoint(Large, ExtraLarge) {
			display: none !important;
		}
	}

	// Float an element to the left.

	.Left {
		float: left;
	}

	// Float an element to the left.

	.Right {
		float: right;
	}

	// -------------------------------------- //
	// -------- General Page Styling -------- //
	// -------------------------------------- //

	// The general page footer.

	#Footer {
		#FooterBranding {
			background-color: $Grey;

			.col1 {
				background-color: $Grey;

				.colblock {
					margin: 1em 0;
				}
			}

			img {
				height: 2.5em;
				vertical-align: top;
			}
		}

		#FooterMain {
			background-color: $GreyD;
			color: $White;

			a {
				color: $White;
			}

			.col1 {
				background-color: $GreyD;

				#FooterMainInner {
					@include DisplayFlexbox();
					@include FlexFlow(row wrap);

					.block {
						/* stylelint-disable-line max-nesting-depth */
						$distance: 2em; // the distance for the margin and padding.

						padding: 0 $distance 0 0;
						margin: 0;
						vertical-align: top;
						box-sizing: border-box;

						@include Breakpoint(Small) {
							/* stylelint-disable-line max-nesting-depth */
							width: 100%;
							padding: 0;

							/* stylelint-disable max-nesting-depth */

							&:nth-of-type(2n + 2),
							&:nth-of-type(2n + 3) {
								margin: $distance 0 0 0;
							}
							/* stylelint-enable max-nesting-depth */
						}

						@include Breakpoint(Medium, Large) {
							/* stylelint-disable-line max-nesting-depth */
							width: 50%;
							/* stylelint-disable max-nesting-depth */

							&:nth-of-type(2n + 3),
							&:nth-of-type(2n + 4) {
								margin: $distance 0 0 0;
							}
							/* stylelint-enable max-nesting-depth */
						}

						@include Breakpoint(ExtraLarge) {
							/* stylelint-disable-line max-nesting-depth */
							width: 25%;
							/* stylelint-disable max-nesting-depth */

							&:nth-of-type(4n + 5),
							&:nth-of-type(4n + 6),
							&:nth-of-type(4n + 7),
							&:nth-of-type(4n + 8) {
								margin: $distance 0 0 0;
							}

							/* stylelint-enable max-nesting-depth */
						}
					}
				}

				.colblock:last-child {
					overflow: visible;

					@include Breakpoint(Large) {
						/* stylelint-disable-line max-nesting-depth */
						margin-top: 4rem;
					}
				}
			}
		}

		#FooterExposure {
			background-color: transparent;
		}
	}

	#FooterDisclaimer {
		text-align: center;

		a {
			color: $GreyD;
		}

		@include Breakpoint(Small, Medium) {
			background-color: $GreyLL;
		}
	}
}
