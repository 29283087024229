/**
 * @file Checkbox.scss
 *
 * Styling for the checkboxes.
 *
 * @author José Lammers <jose@studyportals.com>
 * @author Mike Consemulder <mikeconsemulder@studyportals.com>
 * @copyright © 2016-2017 StudyPortals B.V., all rights reserved.
 * @version 1.0.0
 */
@import './Templates/Shared/CSS/SASSGeneral'; // Importing the main Sass file.

input[type='checkbox'].Checkbox {
	width: 0;
	height: 0;
	visibility: hidden;
	position: absolute;

	+ label::before {
		content: '';
		display: inline-block;
		width: 1rem;
		height: 1rem;
		margin-right: 0.3rem;
		padding: 2px;
		border: 1px solid $GreyDD;
		border-radius: 2px;
		background-color: $White;
		vertical-align: bottom;
		cursor: pointer;
		text-align: center;
		font-family: 'icomoon';
		@include FontSize('N');
		line-height: 1rem;
	}

	+ label:hover::before {
		box-shadow:
			0 0 3px 0 $BrandBlueL,
			inset 0 0 3px 0 $BrandBlueL;
		border-color: $BrandBlueL;
	}

	&:checked + label::before {
		content: '\e934';
		border-color: $BrandBlueL;
		background-color: $BrandBlueL;
		color: $White;
	}

	&.Invert {
		+ label::before {
			border-color: rgba(255, 255, 255, 0.6);
			background-color: transparent;
			color: $White;
		}

		+ label:hover::before {
			box-shadow:
				0 0 3px 0 $White,
				inset 0 0 3px 0 $White;
			border-color: $White;
		}

		&:checked + label::before {
			border-color: $White;
			background-color: $White;
			color: $BrandBlue;
		}
	}

	&:disabled,
	&.Invert:disabled {
		+ label::before {
			border-color: $GreyD;
			background-color: $GreyD;
		}

		+ label:hover::before {
			box-shadow: none;
			border-color: $GreyD;
		}

		&:checked + label::before {
			color: $GreyDD;
		}
	}
}
