/**
 * @file Default.scss
 *
 * Styling that should be used everywhere in Endor
 *
 * This file will extend general styling
 *
 * @author Mariasoprana Atteo <mariasoprana@studyportals.com>
 * @author José Lammers <jose@studyportals.com>
 * @author Niel Hekkens <niel@studyportals.com>
 * @copyright © 2017 StudyPortals B.V., all rights reserved.
 * @version 1.0.0
 */

// Importing the main Default.scss
@import './Templates/Shared/CSS/Default';

// Importing the Endor Sass file.
@import './SASSEndor';

// Endor button styling.
@import 'Patterns/Button';

// Endor form styling.
@import 'Patterns/Form';

// Endor Tags styling.
@import 'Patterns/Tags';

// Endor MultiSelect dropdown styling.
@import 'Patterns/MultiSelectDropdown';

// Endor Warning Messages styling.
@import 'Patterns/WarningMessage';

// Endor Enrolments styling.
@import 'Patterns/EnrolmentEntryPoint';

html,
body {
	height: 100%;
	min-height: 100%;
}

body {
	padding-top: 0 !important; /* TODO: Temporary fix, take out after portal css is fixed */
	color: $GreyDD;
	background-color: $GreyBackground;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $GreyDD;
		font-weight: normal;
	}

	// Standard hyperlink styling.

	a {
		color: inherit;

		&:hover {
			color: inherit;
		}
	}

	p > a,
	span > a {
		color: $BrandBlue;

		&:hover {
			color: $BrandBlueD;
		}

		&:visited {
			color: $BrandBlueD;
		}
	}

	// Checkboxes and radio buttons

	input[type='checkbox'].Checkbox {
		//fix for linear icons, remove when iconset is also used in Endor

		+ label::before {
			font-family: 'icomoon';
		}

		&:checked + label::before {
			content: '\e934';
		}
		//end fix

		&.Partial:checked + label::before {
			content: '\e927';
			@include FontSize('N');
			line-height: 1rem;
			background-color: $White;
			color: $BrandBlueL;
		}

		&.Invert {
			&.Partial:checked + label::before {
				background-color: transparent;
				color: $White;
			}
		}
	}

	input[type='radio'].RadioBtn {
		width: 0;
		height: 0;
		visibility: hidden;

		& + label::before {
			content: '';
			display: inline-block;
			width: 1.2rem;
			height: 1.2rem;
			margin-right: 0.5rem;
			border: 1px solid $GreyD;
			border-radius: 100%;
			background-color: $White;
			vertical-align: middle;
			cursor: pointer;
			text-align: center;
			font-family: 'icomoon';
			@include FontSize('N');
			line-height: 1.2rem;
		}

		& + label:hover::before {
			box-shadow:
				0 0 3px 0 $BrandBlueL,
				inset 0 0 3px 0 $BrandBlueL;
			border-color: $BrandBlueL;
		}

		&:checked + label::before {
			content: '\ea04';
			border-color: $BrandBlueL;
			color: $BrandBlueL;
		}

		&.Invert {
			& + label::before {
				border-color: rgba(255, 255, 255, 0.6);
				background-color: transparent;
			}

			& + label:hover::before {
				box-shadow:
					0 0 3px 0 $White,
					inset 0 0 3px 0 $White;
				border-color: $White;
			}

			&:checked + label::before {
				border-color: $White;
				color: $White;
			}
		}

		&:disabled {
			& + label::before {
				border-color: $Grey;
				background-color: $Grey;
			}

			& + label:hover::before {
				box-shadow: none;
				border-color: $Grey;
			}

			&:checked + label::before {
				border-color: $Grey;
				color: $White;
			}
		}
	}

	// Wrapped implementation

	.Wrapper {
		position: relative;
		max-width: calc(#{$GridMaxWidth} - 3rem);
		margin-left: auto;
		margin-right: auto;
		padding: 0 1.5em;

		@include Breakpoint(Small) {
			padding: 0 0 0 0.5em;
		}

		@include Breakpoint(Medium) {
			padding: 0 0 0 1.5em;
		}
	}

	// White background pane implementation

	.WhiteBackgroundPane {
		background-color: $White;
	}

	/*
		To attach a top border and vertical spacing on each module on Small and Medium
		or to attach vertical spacing on the Large and ExtraLarge breakpoint just add
		the class 'ModuleDivider' on your column variation
	*/
	@include Breakpoint(Small, Medium) {
		.ModuleDivider .colblock,
		.ModuleDivider.colblock {
			div.Module {
				&:not(.EmptyModule) {
					border-color: $GreyBackground;
				}
			}
		}
	}
}
