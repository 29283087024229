/**
 * @file Button.scss
 *
 * Endor styling code for buttons. Extends main styling for buttons
 *
 * @author Mariasoprana Atteo <mariasoprana@studyportals.com>
 * @copyright © 2016 StudyPortals B.V., all rights reserved.
 * @version 1.0.0
 */

// Importing the main Sass file.
@import '../SASSEndor';

// Endor button is $BrandBlue

.Button {
	background-color: $BrandBlue;
	border-color: $BrandBlueD;

	&:hover {
		background-color: $BrandBlueD;
	}

	&:disabled {
		&:hover {
			background-color: $BrandBlue;
		}
	}

	// Attention button is $BrandOrange i.e. Save button in 'unsaved changes status'

	&.Attention {
		background-color: $BrandOrange;
		border-color: $BrandOrangeD;

		&:hover {
			background-color: $BrandOrangeD;
		}

		&:disabled {
			&:hover {
				background-color: $BrandOrange;
			}
		}
	}

	&.Champion {
		@include HeadingSize('H5');
		padding: 17px 24px 16px;
		background-color: $BrandOrange;

		&:hover {
			background-color: $BrandOrangeD;

			&:disabled {
				background-color: $BrandOrange;
			}
		}
	}

	// ACTION IS REQUIRED

	// Endor color (blue): basic action i.e. Add, Close

	&.Ghost {
		min-width: 65px;
		border-color: $BrandBlue;
		background-color: transparent;
		color: $BrandBlue;

		&:hover {
			background-color: $BrandBlue;
			color: $White;
		}

		&:disabled {
			&:hover {
				background-color: transparent;
				color: $BrandBlue;
			}
		}

		// $BrandOrange: Action bringing the user in/out of the flow i.e. Close

		&.Attention {
			border-color: $BrandOrange;
			color: $BrandOrange;

			&:hover {
				background-color: $BrandOrange;
				color: $White;
			}

			&:disabled {
				&:hover {
					background-color: transparent;
					color: $BrandOrange;
				}
			}
		}

		// $Red (red): Action that has a critical impact on data or that are included in critical error messages i.e. Refresh

		&.Critical {
			border-color: $RedError;
			color: $RedError;

			&:hover {
				background: $RedError;
				color: $White;
			}

			&:disabled {
				&:hover {
					background-color: transparent;
					color: $RedError;
				}
			}
		}
	}

	// OPTIONAL ACTION. This class should be combined with color combination i.e. Edit ($BrandBlue), Remove (Critical), Discard (Attention)

	&.Link {
		min-width: 0;
		border: 0;
		padding: 0;
		background-color: transparent;
		color: $BrandBlue;

		&:hover {
			color: $BrandBlueD;
			text-decoration: underline;
		}

		// $BrandOrange: Action bringing the user in/out of the flow i.e. Discard

		&.Attention {
			border-color: $BrandOrange;
			color: $BrandOrange;

			&:hover {
				color: $BrandOrangeD;
				text-decoration: underline;
			}
		}

		// $Red (red): Action that has a critical impact on data or that are included in critical error messages i.e. Remove

		&.Critical {
			border-color: $RedError;
			color: $RedError;

			&:hover {
				color: $Red;
				text-decoration: underline;
			}
		}

		&.ButtonLinksAdd:before {
			border: 1px dotted $BrandBlue;
			content: '+';
			display: inline-block;
			height: 1.5rem;
			margin-right: 0.5rem;
			width: 1.5rem;
		}
	}

	span.ladda-label {
		color: $White;
	}
}
