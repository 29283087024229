/**
 * @file Doraemon.scss
 *
 * Doraemon - Adding spacial gadgets to your life (and lay-out) (https://youtu.be/kbUfBnBz54Q).
 *
 * This file provides a 12 column responsive grid with multiple variations.
 *
 * @author Mariasoprana Atteo <mariasoprana@studyportals.com>
 * @copyright © 2016 StudyPortals B.V., all rights reserved.
 * @version 1.0.0
 *
 * For a full explanation see the wiki: https://github.com/studyportals/Shared/wiki/Galactus-responsive-grid.
 */

// Importing the main Sass file.
@import '../SASSEndor';
/*
--------------------------------------------------------------------------------
	Reset styling
--------------------------------------------------------------------------------
*/

html,
body,
.colblock,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
th,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
p {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
	line-height: 1.5em;
}

html {
	font-size: 16px; // To make sure that our "em" sizing works properly.

	// Decrease the font-size on the root level for every smaller breakpoint.
	@include Breakpoint(Small) {
		font-size: 85%;
	}

	@include Breakpoint(Medium) {
		font-size: 90%;
	}

	@include Breakpoint(Large) {
		font-size: 95%;
	}

	@include Breakpoint(ExtraLarge) {
		font-size: 100%;
	}
}

body {
	font-size: 100%; // Font-size calculated for the active breakpoint from a base value of 16px.
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
	display: block;
}

a:active {
	outline: none;
}

:focus {
	-moz-outline-style: none;
	outline-style: none;
	outline: none;
}

blockquote,
q {
	quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

// Removing the 'custom' appearance of form fields to resemble native form fields on webkit devices.

textarea,
input[type='text'],
input[type='number'],
input[type='date'],
input[type='email'],
input[type='password'],
input[type='search'],
input[type='submit'],
input[type='tel'],
button {
	-webkit-appearance: none;
}

input,
textarea,
select {
	border-radius: 0;
	border: 1px solid $GreyD;
}

// Make sure all form elements inherit the font-family set on page level.

input,
textarea,
select,
option,
button {
	font-family: inherit;
}

/*
--------------------------------------------------------------------------------
	Main 2 column grid
--------------------------------------------------------------------------------
*/

#doraemon {
	/*
	----------------------------------------------------------------------------
		Required Sass logic
	----------------------------------------------------------------------------
	*/

	$HorizontalGutterMobile: 0.5rem;
	$Zero: 0rem;

	/*
		Calculates the width for the given amount of columns.
		Formula: (100% - gutter) * amount of columns / 12 columns - gutter
	*/

	@function CalculateColumns($HorizontalGutter, $Multiplier) {
		@return calc((100% - #{$HorizontalGutter}) * #{$Multiplier} / 12 - #{$HorizontalGutter});
	}

	/*
		Calculates the position for the given amount of columns.
		Formula: (100% - gutter) * amount of columns / 12 columns + gutter
	*/

	@function CalculatePosition($HorizontalGutter, $Multiplier, $ColumnSpacing) {
		@return calc((100% - #{$HorizontalGutter}) * #{$Multiplier} / 12 + #{$HorizontalGutter});
	}

	@mixin VerticalMargin() {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}

	@mixin VerticalPadding() {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	/*
	--------------------------------------------------------------------------------
		General
	--------------------------------------------------------------------------------
	*/
	-webkit-box-sizing: border-box; /* Safari, other WebKit */
	-moz-box-sizing: border-box; /* Firefox, other Gecko */
	box-sizing: border-box; /* Opera/IE 8+ */

	position: relative;
	min-width: 768px; // To make sure that the lay-out does not break on low resolution screens.
	width: 100%;
	font-size: 1em; // Equals a font-size of 100% or a base font-size of 16px.
	word-wrap: break-word;
	margin: 0;
	padding: 0;
	transition: all 0.2s linear;
	overflow: visible;

	.col1,
	.col2,
	.col2-right,
	.col2-left,
	.nav-bar::after {
		clear: both;
	}

	.col1,
	.col2,
	.col2-right,
	.col2-left {
		@include VerticalPadding;
	}

	.col1::after,
	.col2::after,
	.col2-right::after,
	.col2-left::after,
	.nav-bar::after {
		content: '';
		clear: both;
		display: block;
	}

	.col2 .colblock,
	.col2-right .colblock,
	.col2-left .colblock {
		float: left;
	}

	.col1 .colblock,
	.col2 .colblock,
	.col2-right .colblock,
	.col2-left .colblock {
		overflow: inherit;
		box-sizing: border-box;
		position: relative;
	}

	.col1 .colblock:nth-child(1n + 1),
	.col2 .colblock:nth-child(1n + 1),
	.col2-right .colblock:nth-child(1n + 1),
	.col2-left .colblock:nth-child(1n + 1) {
		margin-left: $Zero;
		margin-right: $Zero;
		@include VerticalMargin;
		clear: both;
	}

	@include Breakpoint(Medium) {
		.col1 > .colblock {
			float: left;
			margin-left: $HorizontalGutter;
			margin-right: $HorizontalGutter;
			width: CalculateColumns($HorizontalGutter, 12);
		}

		.col2 > .colblock {
			float: left;
			width: CalculateColumns($HorizontalGutter, 6);
		}

		.col2 > .colblock:nth-child(2n + 1) {
			margin-left: $HorizontalGutter;
			@include VerticalMargin;
			clear: both;
		}

		.col2 > .colblock:nth-child(2n + 2) {
			@include VerticalMargin;
			clear: none;

			&.AddGutter {
				margin-left: $HorizontalGutter;
			}
		}

		.col2-right > .colblock {
			float: left;
			width: CalculateColumns($Zero, 10);
		}

		.col2-right > .colblock:nth-child(2n + 1) {
			@include VerticalMargin;
			clear: both;
			width: CalculateColumns($Zero, 2);
		}

		.col2-right > .colblock:nth-child(2n + 2) {
			margin-left: $Zero;
			@include VerticalMargin;
			clear: none;
		}

		.col2-left > .colblock {
			float: left;
			width: CalculateColumns($HorizontalGutter, 12);
		}

		.col2-left > .colblock:nth-child(2n + 1) {
			margin-left: $HorizontalGutter;
			@include VerticalMargin;
			clear: both;
		}

		.col2-left > .colblock:nth-child(2n + 2) {
			margin-left: $HorizontalGutter;
			@include VerticalMargin;
			clear: both;
			width: CalculateColumns($HorizontalGutter, 12);
		}
	}

	@include Breakpoint(Large) {
		.col1 > .colblock {
			float: left;
			margin-left: $HorizontalGutter;
			margin-right: $HorizontalGutter;
			width: calc(100% - (#{$HorizontalGutter} * 2));
		}

		.col2 > .colblock {
			float: left;
			width: CalculateColumns($HorizontalGutter, 6);
		}

		.col2 > .colblock:nth-child(2n + 1) {
			margin-left: $HorizontalGutter;
			@include VerticalMargin;
			clear: both;
		}

		.col2 > .colblock:nth-child(2n + 2) {
			@include VerticalMargin;
			clear: none;

			&.AddGutter {
				margin-left: $HorizontalGutter;
			}
		}

		.col2-right > .colblock {
			float: left;
			width: CalculateColumns($Zero, 10);
		}

		.col2-right > .colblock:nth-child(2n + 1) {
			@include VerticalMargin;
			clear: both;
			width: CalculateColumns($Zero, 2);
		}

		.col2-right > .colblock:nth-child(2n + 2) {
			margin-left: $Zero;
			@include VerticalMargin;
			clear: none;
		}

		.col2-left > .colblock {
			float: left;
			width: CalculateColumns($HorizontalGutter, 4);
		}

		.col2-left > .colblock:nth-child(2n + 1) {
			margin-left: $HorizontalGutter;
			@include VerticalMargin;
			clear: both;
		}

		.col2-left > .colblock:nth-child(2n + 2) {
			@include VerticalMargin;
			clear: none;
			width: CalculateColumns($HorizontalGutter, 8);
		}
	}

	@include Breakpoint(ExtraLarge) {
		.col1 > .colblock {
			float: left;
			margin-left: $HorizontalGutter;
			margin-right: $HorizontalGutter;
			width: calc(100% - (#{$HorizontalGutter} * 2));
		}

		.col2 > .colblock {
			float: left;
			width: CalculateColumns($HorizontalGutter, 6);
		}

		.col2 > .colblock:nth-child(2n + 1) {
			margin-left: $HorizontalGutter;
			@include VerticalMargin;
			clear: both;
		}

		.col2 > .colblock:nth-child(2n + 2) {
			@include VerticalMargin;
			clear: none;

			&.AddGutter {
				margin-left: $HorizontalGutter;
			}
		}

		.col2-right > .colblock {
			float: left;
			width: CalculateColumns($Zero, 10);
		}

		.col2-right > .colblock:nth-child(2n + 1) {
			@include VerticalMargin;
			clear: both;
			width: CalculateColumns($Zero, 2);
		}

		.col2-right > .colblock:nth-child(2n + 2) {
			margin-left: $Zero;
			@include VerticalMargin;
			clear: none;
		}

		.col2-left > .colblock {
			float: left;
		}

		.col2-left > .colblock:nth-child(2n + 1) {
			margin-left: $HorizontalGutter;
			@include VerticalMargin;
			clear: both;
			width: CalculateColumns($HorizontalGutter, 10);
		}

		.col2-left > .colblock:nth-child(2n + 2) {
			@include VerticalMargin;
			clear: none;
			width: CalculateColumns($HorizontalGutter, 2);
		}
	}

	/*
	----------------------------------------------------------------------------
		Additional/ exceptional styling
	----------------------------------------------------------------------------
	*/

	img {
		max-width: 100%;
		width: auto; // IE8 fix.
		height: auto;
	}

	// To really force the overriding of properties we use !important, there is no way around it.

	.no-top-margin {
		margin-top: 0 !important;
	}

	.no-bottom-margin {
		margin-bottom: 0 !important;
	}

	.no-vertical-margin {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	.no-horizontal-margin {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

	// Padding

	.no-top-padding {
		padding-top: 0 !important;
	}

	.no-bottom-padding {
		padding-bottom: 0 !important;
	}

	.no-vertical-padding {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}

	.no-horizontal-padding {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.col1,
	.col2,
	.col2-right,
	.col2-left {
		/*
			Attaches a page wrapper for all the row variations (except for .nav-bar).
		*/

		&.wrapped {
			max-width: $GridMaxWidth;
			margin-left: auto !important;
			margin-right: auto !important;
			float: none !important;
		}
	}

	.wrapped.col1 > .colblock:first-of-type {
		float: none;
		margin-left: auto !important;
		margin-right: auto !important;
	}

	.col1 {
		.colblock {
			/*
				Attaches an element wrapper for all 'colblock' elements within
				the row variations (except for .nav-bar). This wrapper is only
				intended for improving readability, not as a page wrapper.
			*/

			.readability-wrapped {
				max-width: 960px;
				margin: 0 auto;
			}
		}
	}

	/* Nested grid */

	.col1 > .colblock,
	.col2 > .colblock,
	.col2-right > .colblock,
	.col2-left > .colblock {
		.col1 {
			.colblock {
				margin-left: $Zero;
				margin-right: $Zero;
			}
		}

		.col2-left {
			padding: $HorizontalGutter;

			& > .colblock {
				margin-right: 0;
				margin-left: 0;
				float: left;

				&:nth-child(2n + 1) {
					width: CalculateColumns($Zero, 9);
				}

				&:nth-child(2n + 2) {
					width: CalculateColumns($Zero, 3);
				}

				&:nth-child(1) {
					padding-left: 0;
				}
			}
		}

		.col2 {
			padding: $HorizontalGutter;

			& > .colblock {
				width: CalculateColumns($Zero, 6);
			}

			& > .colblock:nth-child(2n + 1) {
				margin-left: $Zero;
			}
		}
	}
}
