/**
 * @file PowerPopup.scss
 *
 * Styling for the altered version of the Popup.
 *
 * @author Joos Huys <joos@studyportals.com>
 * @author Dennis de Wolf <dennis@studyportals.com>
 * @author Stefan Klokgieters <stefan@studyportals.com>
 */

@import './Templates/Shared/CSS/_SASSGeneral.scss'; // Importing the main Sass file.

.PowerPopupWrapper {
	@include DisplayFlexbox();
	@include AlignItems(center);
	@include JustifyContent(center);
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: $PentHouse;
	background: rgba(49, 50, 51, 0.7);
	overflow-y: visible;
	overflow-x: hidden;
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
	-webkit-overflow-scrolling: touch;
	cursor: pointer;

	&.Top {
		@include Breakpoint(Small, Medium) {
			@include AlignItems(flex-start);
			padding-top: 1rem;
		}
	}

	&.Bottom {
		@include Breakpoint(Small, Medium) {
			@include AlignItems(flex-end);
			padding-bottom: 1rem;
		}
	}

	.PowerPopup {
		position: relative;
		display: block;
		width: calc(100% - 1rem);
		max-width: 30rem;
		max-height: calc(100% - 1rem);
		overflow-y: auto;
		overflow-x: hidden;
		background: $White;
		cursor: default;
		padding: 0.75rem;
		border-radius: 3px;
		box-sizing: border-box;
		min-height: 4rem;

		&.Large {
			max-width: 40rem;
		}

		&.NoPadding {
			padding: 0;
		}

		.CloseButton {
			position: absolute;
			top: 0;
			right: 0;
			width: 3rem;
			height: 3rem;
			display: block;
			overflow: hidden;
			text-decoration: none;
			z-index: $FirstFloor;

			.CloseIcon {
				font-size: 1.4rem;
				position: absolute;
				top: 50%;
				left: 50%;
				@include Transform(translate(-50%, -50%));
				color: $Grey;

				&:hover {
					color: $GreyD;
				}
			}
		}

		.PowerPopupContent {
			clear: both;
		}
	}
}
