// Importing the main Sass file.
@import '../SASSEndor';

.EnrolmentReviewEntry,
.EnrollmentVerificationEntry {
	max-width: 1220px;
	height: 27rem;
	margin: 0 auto;
	background-color: $White;
	position: relative;

	.ModuleDescriptionContainer {
		text-align: center;
		padding: 2rem 3rem 0;

		.ModuleImage {
			margin-bottom: 0.5rem;
		}

		.ModuleTitle {
			@include HeadingSize('H3');
			margin-bottom: 1.5rem;
		}

		.ModuleDescription {
			@include FontSize('N');
			text-align: left;
		}
	}

	.ModuleAction {
		width: 100%;
		position: absolute;
		bottom: 0;

		.HasAccess {
			padding: 2rem 3rem;
			float: right;

			.Button {
				background-color: $BrandBlue;
				border-color: $BrandBlueD;

				&:hover {
					background-color: $BrandBlueD;
				}
			}
		}

		.NoAccess {
			@include FontSize('SN');
			padding: 1.5rem 2rem;
			background: $YellowLL;

			.LampIcon {
				color: $Yellow;
				font-size: 1.4rem;
				vertical-align: middle;
				margin-right: 0.5rem;
			}
		}
	}
}
