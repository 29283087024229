/**
 * @file FontSizes.css
 *
 * (Global) font sizes.
 *
 * @author Stefan Klokgieters <stefan@studyportals.com>
 * @author Alexandra Marginean <alexia@studyportals.com>
 * @copyright © 2014-2017 StudyPortals B.V., all rights reserved.
 * @version 2.0.0
 */

@use 'sass:math';

$XS: 0.6rem; // 9.6 px
$S: 0.8rem; // 12.8px
$SN: 0.875rem; // 14px

@mixin FontSize($Size) {
	$root: 16;

	@if $Size == S {
		font-size: math.div(11, $root) + rem;
		line-height: 1rem;
	} @else if $Size == SN {
		font-size: math.div(13, $root) + rem;
		line-height: 1rem;
	} @else if $Size == N {
		font-size: math.div(15, $root) + rem;
		line-height: 1.5rem;
	} @else {
		@error 'Please use a valid font size!';
	}
}
