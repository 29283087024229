/**
 * @file Z-Index.scss
 *
 * (Global) z-index values.
 * Z-index on relative objects is relative! But we still recommend using the
 * variables to avoid weird numbers.
 *
 * @author Stefan Klokgieters <stefan@studyportals.com>
 * @author Johan Voorhout <johan@studyportals.com>
 * @author Frank Kluijtmans <frank@studyportals.com>
 * @copyright © 2015 StudyPortals B.V., all rights reserved.
 * @version 1.0.0
 */

$GroundFloor: 0; // Use this to reset items or place them at the bottom.
$FirstFloor: 25; // Use this for creating a new stacking context within an element.
$SecondFloor: 50; // Middle level for specific tweaking.
$ThirdFloor: 75; // Header, Tooltips, Fixed elements.
$FourthFloor: 100; // Overlay for highlighting elements, Cookie wall.
$PentHouse: 125; // Full page overlays and it’s contents, Highlighted elements.
