/**
 * @file Error.scss
 *
 * Structural styling of the Error Handler module.
 *
 * @author Mariasoprana Atteo <mariasoprana@studyportals.com>
 * @copyright © 2016 StudyPortals B.V., all rights reserved.
 * @version 1.0.0
 */

@import './Templates/Endor/CSS/_SASSEndor.scss';

body {
	background: $White;
}

#ErrorPage {
	img {
		box-sizing: border-box;
		max-width: 100%;
	}

	.row {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		margin: 0 auto;

		.col-12-small {
			width: 100%;
			float: none;
		}

		@include Breakpoint(Medium, Large, ExtraLarge) {
			img {
				padding: 2rem 4rem 0 0;
			}

			.col-3-large {
				width: 25%;
				float: left;
			}

			.col-9-large {
				width: 75%;
				float: left;
			}
		}

		@include Breakpoint(Medium) {
			img {
				padding: 2rem 1rem 0 0;
			}
		}
	}

	h1 {
		@include HeadingSize('H3');
		line-height: 1;
		margin-top: 2rem;
	}

	.HintMessage {
		@include FontSize('N');
		margin-top: 2.5rem;
	}

	.Button {
		display: inline-block;
		margin: 1rem 1rem 0 0;
	}
}
