/**
 * @file Animations.scss
 *
 * General animations.
 *
 * @author Dennis de Wolf <dennis@studyportals.com>
 * @copyright © 2017 StudyPortals B.V., all rights reserved.
 * @version 1.0.0
 */

@import '../SASSGeneral.scss'; // Importing the main SASS file.

// Introduce an element to the user by having it jump into view.

.AnimationPopIn {
	animation: 0.2s PopIn ease forwards;
}
@keyframes PopIn {
	0% {
		-ms-transform: scale(0); /* IE 9 */
		-webkit-transform: scale(0); /* Chrome, Safari, Opera */
		transform: scale(0);
	}

	100% {
		-ms-transform: scale(1); /* IE 9 */
		-webkit-transform: scale(1); /* Chrome, Safari, Opera */
		transform: scale(1);
	}
}

/** Remove an element from the users view by having it jump out of view. **/

.AnimationPopOut {
	animation: 0.2s PopOut ease forwards;
}

@keyframes PopOut {
	0% {
		-ms-transform: scale(1); /* IE 9 */
		-webkit-transform: scale(1); /* Chrome, Safari, Opera */
		transform: scale(1);
	}

	100% {
		-ms-transform: scale(0); /* IE 9 */
		-webkit-transform: scale(0); /* Chrome, Safari, Opera */
		transform: scale(0);
	}
}

/** Introduce an element into view by fading it in **/

.AnimationFadeIn {
	animation: 1s FadeIn ease forwards;
}

@keyframes FadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

/** Remove an element from view by fading it out. **/

.AnimationFadeOut {
	animation: 0.6s FadeOut ease forwards;
}

@keyframes FadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

/** Give emphasis to an item by shaking it on it's horizontal axis. **/

.AnimationShakeHorizontal {
	animation: 0.1s ShakeHorizontal linear 3 forwards;
}

/** Looped version of shakeHorizontal **/

.AnimationShakeHorizontalLoop {
	animation: 0.1s ShakeHorizontal linear infinite;
}

@keyframes ShakeHorizontal {
	0% {
		transform: translateX(0);
	}

	40% {
		transform: translateX(-0.3rem);
	}

	80% {
		transform: translateX(0.3rem);
	}

	100% {
		transform: translateX(0rem);
	}
}

/** Show that an element is highlighted by raising it up in depth. **/

.AnimationRaise {
	animation: 0.2s Raise linear forwards;
}

@keyframes Raise {
	0% {
		transform: scale(1);
		box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
	}

	100% {
		transform: scale(1.1);
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
	}
}

/** Show that an element is no longer highlighted by lowering it down in depth. **/

.AnimationLower {
	animation: 0.2s Lower linear forwards;
}

@keyframes Lower {
	0% {
		transform: scale(1.1);
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
	}

	100% {
		transform: scale(1);
		box-shadow: 0 0 0 rgba(0, 0, 0, 0);
	}
}

/** Give emphasis to an element by making it bounce up and down **/

.AnimationBounce {
	animation: 0.6s Bounce ease forwards;
}

/** Looped version of Bounce **/

.AnimationBounceLoop {
	animation: 0.6s Bounce ease infinite;
}

@keyframes Bounce {
	0% {
		transform: translateY(0);
	}

	30% {
		transform: translateY(-0.5rem);
	}

	50% {
		transform: translateY(0rem);
	}

	60% {
		transform: translateY(-0.4rem);
	}

	100% {
		transform: translateY(0rem);
	}
}

/** Convey work happening on the background by making an item pulse. **/

.AnimationPulse {
	animation: 1s Pulse linear 2 forwards;
}

/** Looped version of Pulse **/

.AnimationPulseLoop {
	animation: 1s Pulse linear infinite;
}

@keyframes Pulse {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0.5;
	}

	100% {
		opacity: 1;
	}
}

/** Slide an element in view from the left. **/

.AnimationSlideLeftIn {
	animation: 0.5s SlideLeftIn ease forwards;
}

@keyframes SlideLeftIn {
	0% {
		transform: translateX(-200%);
		opacity: 0;
	}

	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

/** Slide an element out of view to the left. **/

.AnimationSlideLeftOut {
	animation: 0.5s SlideLeftOut ease forwards;
}

@keyframes SlideLeftOut {
	0% {
		transform: translateX(0);
		opacity: 1;
	}

	100% {
		transform: translateX(-200%);
		opacity: 0;
	}
}

/** Slide an element out of view to the right. **/

.AnimationSlideRightOut {
	animation: 0.5s SlideRightOut ease forwards;
}

@keyframes SlideRightOut {
	0% {
		transform: translateX(0);
		opacity: 1;
	}

	100% {
		transform: translateX(200%);
		opacity: 0;
	}
}

/** Slide an element into view from the right. **/

.AnimationSlideRightIn {
	animation: 0.5s SlideRightIn ease forwards;
}

@keyframes SlideRightIn {
	0% {
		transform: translateX(200%);
		opacity: 0;
	}

	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

/** Slide an element in view from the top. **/

.AnimationSlideIn {
	animation: 0.2s SlideIn ease forwards;
}

@keyframes SlideIn {
	0% {
		transform: translateY(-100%);
		opacity: 0;
	}

	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
/** Slide an element out of view to the top **/

.AnimationSlideOut {
	animation: 0.2s SlideOut ease forwards;
}

@keyframes SlideOut {
	0% {
		transform: translateY(0);
		opacity: 1;
	}

	100% {
		transform: translateY(-100%);
		opacity: 0;
	}
}

/** Slide an element out of view to the top **/

.AnimationSpin {
	animation: 1s Spin linear infinite;
}

@keyframes Spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
