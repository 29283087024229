/**
 * @file _HeadingSizes.css
 *
 * @version 1.0.0
 * @copyright © 2017 StudyPortals B.V., all rights reserved.
 */

@use 'sass:math';

@mixin HeadingSize($Size) {
	$root: 16;

	@if $Size == H1 {
		font-size: math.div(34, $root) + rem;
		line-height: math.div(48, $root) + rem;
	} @else if $Size == H1-hero {
		font-size: math.div(69, $root) + rem;
		line-height: math.div(88, $root) + rem;

		@include Breakpoint(Small, Medium) {
			font-size: math.div(27, $root) + rem;
			line-height: math.div(40, $root) + rem;
		}
	} @else if $Size == H1-cover {
		font-size: math.div(43, $root) + rem;
		line-height: math.div(56, $root) + rem;
	} @else if $Size == H2 {
		font-size: math.div(27, $root) + rem;
		line-height: math.div(40, $root) + rem;

		@include Breakpoint(Small, Medium) {
			font-size: math.div(19, $root) + rem;
			line-height: math.div(24, $root) + rem;
		}
	} @else if $Size == H3 {
		font-size: math.div(24, $root) + rem;
		line-height: math.div(32, $root) + rem;
	} @else if $Size == H4 {
		font-size: math.div(19, $root) + rem;
		line-height: math.div(24, $root) + rem;
	} @else if $Size == H5 or H6 {
		font-size: math.div(17, $root) + rem;
		line-height: math.div(24, $root) + rem;
	} @else {
		@error 'Please use a valid heading!';
	}
}
