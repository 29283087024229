/**
 * @file OpenSans.scss
 *
 * Latin (latin)
 * Latin Extended (latin-ext)
 *
 * @author Stefan Klokgieters <stefan@studyportals.com>
 * @version 1.2.0
 * @copyright © 2014-2015 StudyPortals B.V., all rights reserved.
 */

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600&subset=latin-ext');
