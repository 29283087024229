/**
 * @file Button.scss
 *
 * General button styling
 *
 * @author Bram van der Putten <bram@studyportals.com>
 * @author Stefan Klokgieters <stefan@studyportals.com>
 * @author Stanley Clark <stanley@studyportals.com>
 * @author Steve Zeeuwe <steve@studyportals.com>
 * @author Pieter Looijmans <pieter@studyportals.com>
 * @author Joos Huys <joos@studyportals.com>
 * @copyright © 2014-2017 StudyPortals B.V., all rights reserved.
 * @version 1.5.1
 */

@import '../SASSGeneral.scss'; // Importing the main SASS file.

.Button {
	text-decoration: none;
	padding: 0.5rem 1rem;
	border-radius: 3px;
	text-align: center;
	cursor: pointer;
	display: inline-block;
	background-color: $BrandOrange;
	border: 0 solid $BrandOrangeD;
	border-bottom-width: 2px;
	color: $White;
	font-size: inherit;
	transition: all 0.15s linear;
	box-sizing: border-box;

	&:hover {
		color: $White;
		background-color: $BrandOrangeD;
		text-decoration: none;
	}

	/* On browsers which support touch, overwrite any hover-related styling. */
	@media (hover: none) {
		&:hover {
			background-color: $BrandOrange;
		}
	}

	&:visited {
		color: $White;
	}

	&:disabled {
		opacity: 0.5;

		&:hover {
			background-color: $BrandOrange;
		}
	}

	&.Blue {
		border: 1px solid $BrandBlue;
		background-color: $BrandBlue;

		&:hover {
			background-color: $BrandBlueD;
			border: 1px solid $BrandBlueD;
		}

		/* On browsers which support touch, overwrite any hover-related styling. */
		@media (hover: none) {
			&:hover {
				border: 1px solid $BrandBlue;
				background-color: $BrandBlue;
			}
		}

		&.Inverted {
			color: $BrandBlue;
			background-color: $White;
			border: 1px solid $BrandBlue;

			&:hover {
				background-color: $GreyLLL;
			}

			/* On browsers which support touch, overwrite any hover-related styling. */
			@media (hover: none) {
				&:hover {
					background-color: $White;
				}
			}
		}
	}

	&.Ghost {
		$VerticalPadding: calc(0.5rem - 1px);
		border: 1px solid $BrandOrange;
		color: $BrandOrange;
		background-color: transparent;
		padding: $VerticalPadding 1rem;

		&:hover {
			color: $White;
			background-color: $BrandOrange;
		}

		/* On browsers which support touch, overwrite any hover-related styling. */
		@media (hover: none) {
			&:hover {
				color: $BrandOrange;
				background-color: transparent;
			}
		}

		&.Blue {
			color: $GreyDD;
			border: 1px solid $BrandBlue;

			&:hover {
				background-color: $BrandBlue;
				border: 1px solid $BrandBlue;
				color: $White;
			}

			/* On browsers which support touch, overwrite any hover-related styling. */
			@media (hover: none) {
				&:hover {
					background-color: transparent;
					color: $GreyDD;
				}
			}
		}
	}

	&.Form {
		padding-top: 0.3rem;
		padding-bottom: 0.3rem;
	}
}
