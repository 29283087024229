/**
 * @file Popup.scss
 *
 * Generic popup styling.
 *
 * @author Stefan Klokgieters <stefan@studyportals.com>
 * @version 4.0.2
 * @copyright © 2014-2016 StudyPortals B.V., all rights reserved.
 */

@import './Templates/Shared/CSS/_SASSGeneral.scss'; // Importing the main Sass file.

.PopupVisible {
	opacity: 1 !important;
}

.Popup {
	@include DisplayFlexbox();
	@include AlignItems(center);
	@include JustifyContent(center);
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: $PentHouse;
	background: rgba(0, 0, 0, 0.75);
	overflow-y: visible;
	overflow-x: hidden;
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
	-webkit-overflow-scrolling: touch;
	opacity: 0;
	transition: opacity 0.4s ease-in-out;

	&.Auth {
		padding: 0;
	}

	.CloseButton {
		position: relative;
		float: right;
		width: 3rem;
		height: 3rem;
		display: block;
		overflow: hidden;
		text-decoration: none;

		.CloseIcon:before {
			position: absolute;
			top: 50%;
			left: 50%;
			@include Transform(translate(-50%, -50%));
			color: $GreyDD;
		}
	}

	// Add this class on the direct elements inside of .Popup.
	.PopupContent {
		display: block;
		width: 30rem;
		max-height: calc(100% - 3rem);
		overflow-y: auto;
		overflow-x: hidden;
		background: $White;

		@include Breakpoint(Small) {
			width: 100%;
			height: 100%;
			max-height: 100%;
		}
	}
}
