// Importing the main Sass file.
@import '../SASSEndor';

.WarningMessage {
	@include FontSize('N');
	padding: 1rem;
	margin: 1rem 0;
	background-color: $YellowLL;
	border-radius: 1px;
	display: flex;
	align-items: center;

	.WarningIcon {
		font-size: 1.5rem;
		margin-right: 0.5rem;
		color: $Yellow;
	}
}
