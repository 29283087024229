/**
 * @file _SASSEndor.scss
 *
 * All the specific Endor Sass functions, mixins and color palettes.
 *
 * @author José Lammers <jose@studyportals.com>
 * @author Niel Hekkens <niel@studyportals.com>
 * @author Alexandra Marginean <alexia@studyportals.com>
 * @copyright © 2017 StudyPortals B.V., all rights reserved.
 * @version 1.0.1
 */

@import '../../Shared/CSS/SASSGeneral';

/*
--------------------------------------------------------------------------------
	Endor colors
--------------------------------------------------------------------------------
*/

//Does not YET exist in Shared
$Placeholder: $GreyL;

// Endor specific colors
$RedError: #ff0000;
$GreyBackground: #edf2f4;
$EditorBackground: #f4f4f4;

$Disabled: $Placeholder;
$Uneditable: $GreyD;

//Degree
$Bachelor: #e253ff;
$Master: $BrandOrange;
$Phd: #33e3ff;
$ShortCourse: #7ed321;
$Preparation: #f5c923;

//Listing
$Premium: $Blue;
$PremiumSuper: $Pink;
$PremiumLight: #50e3c2;

/*
--------------------------------------------------------------------------------
	Endor page styling
--------------------------------------------------------------------------------
*/
$GridMaxWidth: 1220px;
$HorizontalGutter: 1.25rem;

/*
--------------------------------------------------------------------------------
	Endor mixins
--------------------------------------------------------------------------------
*/

// Box shadow for the columns of the grid
@mixin columnShadow {
	-webkit-box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.1);
}
