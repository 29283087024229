/**
 * @file Form.scss
 *
 * Shared styling code for forms.

 * @author Bram van der Putten <bram@studyportals.com>
 * @author Stefan Klokgieters <stefan@studyportals.com>
 * @version 1.3.2
 *
 * Default form styling
 *
 * Requirements:
 * - A form should get the .Form class
 * - Inside the form should be elements with the class 'Segment', one for every
 * combination (combination of label and input traditionally)
 */

@use 'sass:math';

@import '../SASSGeneral.scss'; // Importing the main SASS file.

.Form {
	input[type='text'],
	input[type='number'],
	input[type='date'],
	input[type='email'],
	input[type='tel'],
	input[type='search'],
	input[type='password'],
	select,
	textarea {
		font-size: inherit;
		line-height: inherit;
		box-sizing: border-box;
		padding: 0.3rem 0.6rem;
		width: 100%;
		border: 1px solid $Grey;
		word-wrap: initial;

		&:focus {
			border-color: $GreyD;
		}
	}

	[disabled='disabled'] {
		opacity: 0.5;
	}

	.Segment {
		display: block;
		margin-bottom: 0.5rem;

		label {
			display: block;
		}

		button {
			width: 100%;
			margin-bottom: 0.5rem;
		}

		.DateSelector {
			$field: math.div(100%, 3);
			$FieldMargin: math.div(1rem, 3);

			input,
			select {
				width: calc(#{$field} - #{$FieldMargin});
				margin-right: 0.5rem;
				float: left;

				&:last-of-type {
					margin-right: 0;
				}
			}
		}

		.Error {
			color: $Red;
			font-size: $SN;
		}
	}

	.PrivacyStatement {
		font-size: $S;
	}

	.errorText {
		color: $Red;
		font-size: $SN;
	}
}
